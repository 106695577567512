import request from '@/utils/request'

// 查询申请记录列表
export function findLeaveWordList(params) {
  return request({
    url: `/leave_words`,
    method: 'get',
    params: params
  })
}

// 更新
export function updateLeaveWordStatus(id, data) {
  return request({
    url: `/leave_words/${id}/using`,
    method: 'patch',
    data
  })
}

// 置顶
export function updateLeaveWordTopping(id) {
  return request({
    url: `/leave_words/${id}/topping`,
    method: 'get'
  })
}
