<template>
  <div>
    <search-leave-words @submit="submitSearch" />

    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a
            v-if="record.status_slug !=='adopted'"
            type="primary"
            @click="confirmToEffective(record)"
          >
            采用
          </a>
          <a
            v-if="record.status_slug !=='not_used'"
            type="primary"
            @click="confirmToIneffective(record)"
          >
            停止采用
          </a>
          <a
            v-if="record.status_slug == 'adopted' && record.topping !== true"
            type="primary"
            @click="topping(record)"
          >
            置顶
          </a>
          <a @click="showOperationHistoryModal(record.id)">操作历史</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="LeaveWord"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchLeaveWords from '@/views/messages/Search'
import { findLeaveWordList, updateLeaveWordStatus, updateLeaveWordTopping } from '@/api/leave_word'

export default {
  name: 'LeaveWordList',
  components: {
    Pagination,
    SearchLeaveWords,
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal')
  },
  data() {
    return {
      query: { status: 'pending' },
      data: [],
      loading: true,
      isShowOperationHistoryModal: false,
      showingOperationHistoryId: 0,
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '留言时间',
          dataIndex: 'created_at',
          width: 180,
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '用户姓名',
          width: 90,
          dataIndex: 'user_name'
        },
        {
          title: '电话',
          width: 200,
          dataIndex: 'phone_number'
        },
        {
          title: '邮箱',
          width: 150,
          dataIndex: 'email'
        },
        {
          title: '留言内容',
          width: 200,
          dataIndex: 'remark'
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'status'
        },
        {
          title: '操作',
          width: 220,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    confirmToEffective(record) {
      const vm = this
      const id = record.id
      this.$confirm({
        title: '确定采用吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          updateLeaveWordStatus(id, { status: 'adopted' }).then((res) => {
            if (res.code === 0) {
              vm.fetchData()
            }
          })
        }
      })
    },
    confirmToIneffective(record) {
      const vm = this
      const id = record.id
      this.$confirm({
        title: '确定停止采用吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          updateLeaveWordStatus(id, { status: 'not_used' }).then((res) => {
            if (res.code === 0) {
              vm.fetchData()
            }
          })
        }
      })
    },
    topping(record) {
      const vm = this
      const id = record.id
      this.$confirm({
        title: '确定置顶吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          updateLeaveWordTopping(id).then((res) => {
            if (res.code === 0) {
              vm.fetchData()
            }
          })
        }
      })
    },
    fetchData() {
      this.loading = true
      findLeaveWordList(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
